/*
react-datepicker のカスタマイズ
*/

/* カレンダー内 今日の日付*/
.alshain-calendar .react-datepicker__day--today {
  border-radius: 0.3rem;
  background-color: #f1f5f9; /* primary 50 */
  color: #1d2088; /* primary 500 */
}

/* カレンダー内 選択状態の日付 */
.alshain-calendar .react-datepicker__day--selected {
  background-color: #0a6bd0; /* accentBlue 500*/
  color: white;
}

/* カレンダー内 「今日」ボタン */
.alshain-calendar .react-datepicker__today-button {
  color: #1d2088; /* primary 500 */

  border-radius: 0 0 5px 5px; /* 角丸が落ちているのを修正 */
}

/* 入力欄の×ボタン */
.alshain-calendar-input-field-clear-button::after {
  background-color: #bdbdbd !important; /* gray 400 */
  content: '✕';
  font-size: 10px;
}
