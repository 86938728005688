/* Spacing */
.content {
  @apply tw-px-4;
}
.content > * + * {
  @apply tw-mt-4;
}

/* Block */
.content .h1 {
  @apply tw-text-xl tw-font-bold tw-leading-normal;
}
.content .h2 {
  @apply tw-mx-[-16px] tw-border-y tw-border-gray-300 tw-bg-primary-50 tw-py-2 tw-px-4 tw-text-base tw-font-bold tw-leading-normal;
}
.content .h3 {
  @apply tw-text-base tw-font-bold tw-leading-normal;
}
.content .h4 {
  @apply tw-text-base tw-font-bold tw-leading-normal;
}
.content .h5 {
  @apply tw-text-sm tw-font-bold tw-leading-normal;
}
.content .h6 {
  @apply tw-text-sm tw-leading-normal;
}
.content .p {
  @apply tw-whitespace-pre-wrap tw-text-sm tw-leading-normal;
}
.content .img {
  @apply tw-flex tw-max-h-[268px] tw-w-full tw-cursor-pointer tw-justify-center tw-bg-primary-50 tw-py-2 lg:tw-max-w-pcHalf;
}
.content .img > img {
  @apply tw-object-scale-down;
}
.content .a {
  @apply tw-text-sm tw-leading-normal tw-text-primary-500 tw-underline tw-transition tw-duration-stateChange hover:tw-opacity-hover;
}
.content blockquote {
  @apply tw-whitespace-pre-wrap tw-rounded tw-bg-primary-50 tw-p-4 tw-text-sm tw-italic tw-text-gray-700;
}
.content code {
  @apply tw-block tw-whitespace-pre-wrap tw-rounded tw-bg-primary-50 tw-p-4;
}

/* List */
.content ul {
  @apply tw-list-disc tw-pl-4;
}
.content ol {
  @apply tw-list-decimal tw-pl-4;
}

/* Table */
.content .tableWrapper {
  @apply tw-overflow-x-auto;
}
.content table {
  @apply tw-w-full tw-whitespace-nowrap tw-border tw-border-primary-100;
}
.content table > tbody > tr > th,
.content table > tbody > tr > td {
  @apply tw-h-8 tw-px-4 tw-text-center tw-font-normal;
}
.content table > tbody > tr > th {
  @apply tw-bg-primary-50;
}
/* Table Border */
.content table > tbody > tr + tr {
  @apply tw-border-t tw-border-primary-100;
}
.content table > tbody > tr > th + th,
.content table > tbody > tr > td + td {
  @apply tw-border-l tw-border-primary-100;
}

/* Text Type */
.content b {
  @apply tw-font-bold;
}
.content i {
  @apply tw-italic;
}
.content u {
  @apply tw-underline;
}

/* Other */
.content hr {
  @apply tw-border-gray-300;
}
