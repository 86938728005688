.wrapper {
  @apply tw-w-full tw-text-center;
}

.header {
  width: 100%;
  height: 80px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;

  display: flex;
  align-items: center;
  justify-content: center;

  @apply tw-h-14 lg:tw-h-20;
}

.logoImage {
  width: 141px;
  height: 49px;
}

.main {
  @apply tw-mx-auto tw-w-full tw-max-w-pcMax;
}

.contents {
  @apply tw-mt-6 lg:tw-mt-18;
  @apply tw-text-left lg:tw-text-center;
}

.illust {
  width: 100%;
  height: 206px;
  margin-top: 40px;
  background-size: 168px 206px;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-image: url(/images/kikumi_busy.png);
}

@media screen and (max-width: 1023px) {
  .illust {
    margin-top: 0;
    background-position: calc(100% - 40px) 0;
  }
}
